import RichTextBlockModel from '../../RichTextBlock/Models/RichTextBlockModel.interface';
import { styled } from '../../stitches.config';
import {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
} from '../../Atoms/Typography/Headings/Heading';
import GridItem from '../../Atoms/Grids/GridItem';
import XhtmlArea from '../../Molecules/XHtmlArea/XHtmlArea';

type PropType = {
  content: RichTextBlockModel;
  blockId: any;
};

type HeaderObject = {
  [key: string]: any;
};

function RichTextBlock({
  content: { header, headerSize, preamble, body },
  blockId,
}: PropType) {
  const headerComponents: HeaderObject = {
    H1: H1,
    H2: H2,
    H3: H3,
    H4: H4,
    H5: H5,
    H6: H6,
  };

  const Header = headerComponents[headerSize as keyof HeaderObject];

  return (
    (header || preamble || body) && (
      <GridItem>
        <Container data-litium-block-id={blockId}>
          {header && (
            <Heading>
              <Header>{header}</Header>
            </Heading>
          )}
          {preamble &&
            preamble.split('\n').map((line) => {
              return <Preamble>{line}</Preamble>;
            })}
          {body && <XhtmlAreaExt content={body} />}
        </Container>
      </GridItem>
    )
  );
}

const Container = styled('div', {
  py: '72px',
});

const Heading = styled('div', {
  pl: 4,
});

const Preamble = styled('div', {
  pl: 4,
  width: '100%',
  '@mediaMinHuge': {
    maxWidth: '50%',
  },
});

const XhtmlAreaExt = styled(XhtmlArea, {
  pl: 4,
  width: '100%',
  '@mediaMinHuge': {
    maxWidth: '50%',
  },
});

export default RichTextBlock;
